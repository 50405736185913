import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  AddProduct,
  AllProducts,
  GetProductById,
  GetProductFeedback,
  GetProductSerialNumber,
  GetProductStats,
  UpdateProductById,
  ValidateProduct,
} from "../../services/product/productService";
import { toast } from "react-toastify";

export const getAllProducts = createAsyncThunk(
  "products/all",
  async ({ detailed, page, size }) => {
    const response = await AllProducts(detailed, page, size);
    return response;
  }
);
export const getProductStats = createAsyncThunk("products/stats", async () => {
  const response = await GetProductStats();
  return response;
});
export const getProductById = createAsyncThunk(
  "product/by/id",
  async ({ id, detailed }) => {
    const response = await GetProductById(id, detailed);
    return response;
  }
);
export const addProduct = createAsyncThunk(
  "create/product",
  async (payload) => {
    const response = await AddProduct(payload);
    return response;
  }
);
export const updateProduct = createAsyncThunk(
  "update/product",
  async ({ id, payload }) => {
    const response = await UpdateProductById(id, payload);
    return response;
  }
);
export const getProductFeedback = createAsyncThunk(
  "product/feedback",
  async ({ productId }) => {
    const response = await GetProductFeedback(productId);
    return response;
  }
);

export const validateProductAsync = createAsyncThunk(
  "product/validate",
  async ({ product_sn, detailed }) => {
    const response = await ValidateProduct(product_sn, detailed);
    return response;
  }
);

export const getProductSerialNumber = createAsyncThunk(
  "products/serial/number",
  async () => {
    const response = await GetProductSerialNumber();
    return response;
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    allProductsResponse: {},
    getProductByIdResponse: {},
    addProductResponse: {},
    updateProductResponse: {},
    productStatsResponse: {},
    productSerialNumberResponse: {},
    productFeedbackResponse: {},
    validateProductResponse: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.allProductsResponse = action.payload;
    });
    builder.addCase(getProductById.fulfilled, (state, action) => {
      state.getProductByIdResponse = action.payload;
    });

    builder.addCase(addProduct.fulfilled, (state, action) => {
      if (action.payload) {
        state.addProductResponse = action.payload;
        state.allProductsResponse.data.items.unshift({
          id: action.payload.data.id,
          serial_number: action.payload.data?.serial_number,
          manufacture_date: action.payload.data?.manufacture_date,
          expiry_date: action.payload.data?.expiry_date,
          thickness: action.payload.data?.thickness,
          volume: action.payload.data?.volume,
          weight: action.payload.data?.weight,
          color: action.payload.data?.color,
          allowable_pressure: action.payload.data?.allowable_pressure,
        });
        toast.success(action.payload.message);
      }
    });

    builder.addCase(addProduct.rejected, (state, action) => {
      toast.error("Failed to Create Product");
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      if (action.payload) {
        state.updateProductResponse = action.payload;
        state.allProductsResponse.data.items.unshift({
          id: action.payload.data.id,
          serial_number: action.payload.data?.serial_number,
          manufacture_date: action.payload.data?.manufacture_date,
          expiry_date: action.payload.data?.expiry_date,
          thickness: action.payload.data?.thickness,
          volume: action.payload.data?.volume,
          weight: action.payload.data?.weight,
          color: action.payload.data?.color,
          allowable_pressure: action.payload.data?.allowable_pressure,
        });
        toast.success(action.payload.message);
      }
    });

    builder.addCase(updateProduct.rejected, (state, action) => {
      toast.error("Failed to Update Product");
    });
    builder.addCase(getProductStats.fulfilled, (state, action) => {
      state.productStatsResponse = action.payload;
    });
    builder.addCase(getProductFeedback.fulfilled, (state, action) => {
      state.productFeedbackResponse = action.payload;
    });

    builder.addCase(validateProductAsync.fulfilled, (state, action) => {
      state.validateProductResponse = action.payload;
      toast.success(action.payload.message);
    });
    builder.addCase(validateProductAsync.rejected, (state, action) => {
      toast.error("Error: Product Not Found");
    });

    builder.addCase(getProductSerialNumber.fulfilled, (state, action) => {
      state.productSerialNumberResponse = action.payload;
    });
  },
});

export default productSlice.reducer;
