import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import originalIcon from "../../../assests/images/original-img.png";
import originalIconBig from "../../../assests/images/original-img-big.png";
import logo from "../../../assests/images/Wooden-heritage-logo-white.png";
import qrCodeImg from "../../../assests/images/qrcode.png";
import Img1 from "../../../assests/images/auth-bg.svg";
import { RiFeedbackFill } from "react-icons/ri";
import { AiOutlinePrinter } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import FeedbackModal from "./feedback-modal";
import { useState } from "react";

function ProductDetailsModal(props) {
  const [modalFeedback, setModalFeedback] = useState(false);

  return (
    <>
      <FeedbackModal
        show={modalFeedback}
        onHide={() => setModalFeedback(false)}
      />{" "}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="modal-header-bg">
          <>
            <Modal.Title className="me-5">
              {" "}
              Elegant Espresso Dining Table Set{" "}
            </Modal.Title>
            <Modal.Title className="mx-5 h6">
              {" "}
              Your Product is <span className="text-danger">Genuine</span>
              <Image src={originalIcon} className="ms-5" />
            </Modal.Title>
          </>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <Row>
              <Col md={6}>
                <div>
                  <Image src={Img1} className="img-fluid" />
                </div>
              </Col>
              <Col md={6}>
                {" "}
                <div>
                  <Image src={Img1} className="img-fluid" />
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col md={3}>
                <div className="mb-5">
                  <small>Product Name</small>
                  <h6>Elegant Espresso Dining Table Set</h6>
                </div>
                <div className="mb-5">
                  <small>Manufacture Date</small>
                  <h6>Elegant Espresso Dining Table Set</h6>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-5">
                  <small>Product Serial Number</small>
                  <h6>Elegant Espresso Dining Table Set</h6>
                </div>

                <div className="mb-5">
                  <small>Manufacture Location</small>
                  <h6>Elegant Espresso Dining Table Set</h6>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <small>Product Description</small>
                  <h6>
                    Introducing our Elegant Espresso Dining Table Set, a perfect
                    addition to elevate your dining experience. This beautifully
                    crafted furniture piece combines style, functionality, and
                    durability to create a stunning focal point in your dining
                    room.
                  </h6>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col md={6} className="me-0 pe-0">
                <Card className="rounded-0">
                  <Card.Body>
                    <h5>Specification</h5>
                    <Row>
                      <Col md={6}>
                        <div className="mb-5">
                          <small>Table Dimension</small>
                          <h6>72 inches (L) x 42 inches (W) x 30 inches (H)</h6>
                        </div>
                        <div className="mb-5">
                          <small>Chair Dimension</small>
                          <h6>Elegant Espresso Dining Table Set</h6>
                        </div>

                        <div className="mb-5">
                          <small>Weight Capacity</small>
                          <h6>Table - 250 lbs, Chair - 300 lbs</h6>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-5">
                          <small>Seat Height </small>
                          <h6>Elegant Espresso Dining Table Set</h6>
                        </div>
                        <div className="mb-5">
                          <small>Wood Type </small>
                          <h6>Elegant Espresso Dining Table Set</h6>
                        </div>
                        <div className="mb-5">
                          <small>Shape </small>
                          <h6>Elegant Espresso Dining Table Set</h6>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="rounded-0">
                  <Card.Body>
                    <Row>
                      <Col md={12}>
                        <div className="my-5">
                          <h4>Key Features</h4>
                          <div className="mb-5">
                            <small>Premium Material</small>
                            <h6>Elegant Espresso Dining Table Set</h6>
                          </div>
                          <div className="mb-5">
                            <small>Espresso Finish </small>
                            <h6>Elegant Espresso Dining Table Set</h6>
                          </div>
                          <div className="mb-5">
                            <small>Comfortable Design </small>
                            <h6>Elegant Espresso Dining Table Set</h6>
                          </div>
                          <div className="mb-5">
                            <small>Comfortable Seating </small>
                            <h6>Elegant Espresso Dining Table Set</h6>
                          </div>
                          <div className="mb-5">
                            <small>Classic Design </small>
                            <h6>Elegant Espresso Dining Table Set</h6>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="ms-0 ps-0">
                <Card className="rounded-0">
                  <Card.Body>
                    <div className="">
                      <h5>Heritage Information</h5>
                      <p>
                        At Wooden Heritage, we are committed to using premium,
                        responsibly-sourced materials in our furniture, and the
                        wood used in our Elegant Espresso Dining Table Set is no
                        exception. We take pride in the heritage and
                        sustainability of the wood that graces your dining room.
                        The wood selected for this dining table set is sourced
                        from well-managed, sustainable forests. We believe in
                        ethical and environmentally-conscious practices, which
                        is why we prioritize using wood that is certified by the
                        Forest Stewardship Council (FSC). The FSC certification
                        ensures that the wood is harvested in a way that
                        protects the ecosystem, promotes responsible forest
                        management, and supports the livelihoods of local
                        communities. The specific wood used in our Elegant
                        Espresso Dining Table Set is American Black Walnut
                        (Juglans nigra). This beautiful hardwood is known for
                        its rich, dark color and exquisite grain patterns. It
                        has a long history of use in fine furniture
                        craftsmanship and is prized for its natural beauty and
                        durability. American Black Walnut has a deep-rooted
                        heritage in American woodworking traditions. Its use
                        dates back centuries, and it has been a favorite choice
                        among skilled artisans for its workability and ability
                        to take on a smooth, polished finish. The wood's
                        distinctive color and grain variations make each piece
                        of furniture truly unique, adding character and charm to
                        your dining room. By choosing our Elegant Espresso
                        Dining Table Set, you not only bring a touch of timeless
                        elegance into your home but also support sustainable
                        forestry practices and the continuation of a rich
                        heritage in American woodworking. We are committed to
                        preserving the legacy of this exceptional wood while
                        providing you with a piece of furniture that will last
                        for generations. Experience the legacy of American
                        craftsmanship and the natural beauty of American Black
                        Dining Table Set. Invest in a piece of furniture that
                        not only enhances your living space.
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="w-50 my-5 clearfix">
            <h4>Certification</h4>
            <div className="my-5 float-start">
              <small>Certificate of Authenticity</small>
              <h6>Certificate of Authenticity.pdf</h6>
            </div>
            <div className="my-5 float-end">
              <small>Carbon Dating Certificate</small>
              <h6>Certificate of Authenticity.pdf</h6>
            </div>
          </div>
          <div className="warranty-section text-white py-5 ">
            <Container>
              <h5>Warranty</h5>
              <p className="my-4 small ">
                We stand behind the quality of our products. This Elegant
                Espresso Dining Table Set comes with a 2-year manufacturer’s
                warranty against defects.
              </p>
              <div>
                <Row>
                  <Col md={3}>
                    <div className="mt-5">
                      {" "}
                      <Image src={logo} width={290} />{" "}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="p-5 small">
                      <p>+234 701 100 3591</p>
                      <p>contact@woodenheritage.com</p>
                      <p>www.woodenheritage.com</p>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mt-5 text-center">
                      <Image src={originalIconBig} width={120} />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mt-5">
                      <Image src={qrCodeImg} width={120} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <div className="my-5">
            <Row>
              <Col md={8}>
                {" "}
                <div className="mt-5">
                  {" "}
                  <p>Share your product authentication</p>{" "}
                  <Button
                    variant="light"
                    className="border-2 border rounded-pill"
                  >
                    https:www.woodenheritage.com/productauth39498
                  </Button>
                  <Button
                    variant="primary"
                    className="rounded-pill btn-1-color border-0 me-3"
                  >
                    Share
                  </Button>{" "}
                  <Button
                    variant="primary rounded-0"
                    onClick={() => setModalFeedback(true)}
                  >
                    {" "}
                    <RiFeedbackFill /> Give Feedback
                  </Button>{" "}
                </div>{" "}
              </Col>
              <Col md={4}>
                <div className="mt-5 pt-5 float-end">
                  <Button variant="light" className="light border-secondary">
                    <AiOutlinePrinter />
                    Print
                  </Button>{" "}
                  <Button variant="light" className="light border-secondary">
                    {" "}
                    <BsDownload /> Download
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
export default ProductDetailsModal;
