import { format } from "date-fns";

const DateTimeDisplay = () => {
  const getCurrentDateTime = () => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "do MMMM yyyy | h:mmaaa");

    return formattedDate;
  };

  const currentDateTime = getCurrentDateTime();

  return <p className="small text-muted">{currentDateTime}</p>;
};

export default DateTimeDisplay;
