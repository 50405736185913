import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assests/style/style.scss";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./error-page";
import { ToastContainer } from "react-toastify";
import store from "./store/store";
import Loading from "./util/loading";
import ProductValidate from "./pages/auth-pages/product-validate";
import Login from "./pages/admin-pages/auth/login";
import Dashboard from "./pages/admin-pages/dashboard";
import CylinderManagement from "./pages/admin-pages/products/cylinders";
import FeedbackAndSupport from "./pages/admin-pages/feedback/feedback";
import Users from "./pages/admin-pages/users/users";
import ProductDetails from "./pages/auth-pages/product-details";
import ProductInfo from "./pages/auth-pages/product-info";
import { ProtectedRoute } from "./util/privateRoutes";
import ForgetPassword from "./pages/admin-pages/auth/forget-password";
import ResetPassword from "./pages/admin-pages/auth/reset-password";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProductValidate />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: `/reset-password/:token`,
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),

    errorElement: <ErrorPage />,
  },
  {
    path: "/cylinders",
    element: (
      <ProtectedRoute>
        <CylinderManagement />
      </ProtectedRoute>
    ),

    errorElement: <ErrorPage />,
  },
  {
    path: "/product-details",
    element: <ProductDetails />,
    errorElement: <ErrorPage />,
  },
  {
    path: `/product/:productId`,
    element: <ProductInfo />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/feedback-support",
    element: (
      <ProtectedRoute>
        <FeedbackAndSupport />
      </ProtectedRoute>
    ),

    errorElement: <ErrorPage />,
  },
  {
    path: "/users",
    element: (
      <ProtectedRoute>
        <Users />
      </ProtectedRoute>
    ),

    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer hideProgressBar />
      <Loading />
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
