import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Departments, Positions, Roles } from "../../services/data/dataService";

export const getAllRoles = createAsyncThunk("roles/all", async () => {
  const response = await Roles();
  return response;
});
export const getAllPosition = createAsyncThunk("position/all", async () => {
  const response = await Positions();
  return response;
});
export const getAllDepartment = createAsyncThunk("department/all", async () => {
  const response = await Departments();
  return response;
});

const dataSlice = createSlice({
  name: "data",
  initialState: {
    roles: {},
    position: {},
    departments: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    });
    builder.addCase(getAllPosition.fulfilled, (state, action) => {
      state.position = action.payload;
    });
    builder.addCase(getAllDepartment.fulfilled, (state, action) => {
      state.departments = action.payload;
    });
  },
});

export default dataSlice.reducer;
