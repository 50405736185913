import { GetRequest, PostRequest, PutRequest } from "../../util/apiMethods";

export const supportForm = async (body) => {
  const response = await PostRequest("/support_tickets", body);
  return response;
};
export const getAllSupportDetails = async (detailed, page) => {
  const response = await GetRequest(
    `support_tickets?detailed${detailed}&page=${page}`
  );
  return response;
};
export const getAllSupportStats = async () => {
  const response = await GetRequest("/support_tickets/stats");
  return response;
};

export const getSupportDetailsById = async (ticket_id, detailed) => {
  const response = await GetRequest(
    `support_tickets/${ticket_id}?detailed=${detailed}`
  );
  return response;
};

export const updateSupportStatus = async (id, body) => {
  const response = await PutRequest(`/support_tickets/${id}`, body);
  return response;
};

export const getAllFeedbackDetails = async (detailed, page, size) => {
  const response = await GetRequest(
    `feedbacks?detailed${detailed}&page=${page}&size=${size}`
  );
  return response;
};
export const getAllFeedbackStats = async () => {
  const response = await GetRequest("/feedbacks/stats");
  return response;
};

export const getFeedbackDetailsById = async (feedback_id, detailed) => {
  const response = await GetRequest(
    `feedbacks/${feedback_id}?detailed=${detailed}`
  );
  return response;
};

export const addFeedback = async (body) => {
  const response = await PostRequest("/feedbacks", body);
  return response;
};

export const updateFeedbackStatus = async (id, body) => {
  const response = await PutRequest(`/feedbacks/${id}`, body);
  return response;
};
