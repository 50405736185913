import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";

import { Formik } from "formik";
import Layout from "../../components/layouts/layout";
import ProductDetailsModal from "./modals/product-details-modal";
import { useState } from "react";
import FeedbackModal from "./modals/feedback-modal";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { validateProductAsync } from "../../slices/product/productSlice";
import { useNavigate } from "react-router-dom";
import CameraButton from "../../util/QRCodeScanner";

function ProductValidate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalProductDetails, setModalProductDetails] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  return (
    <Layout>
      <ProductDetailsModal
        show={modalProductDetails}
        onHide={() => setModalProductDetails(false)}
        fullscreen={fullscreen}
      />
      <FeedbackModal
        show={modalFeedback}
        onHide={() => setModalFeedback(false)}
      />
      <div className="hero-section">
        <Container>
          <Row>
            <Col md={6}>
              <div className="pt-5 text-white">
                <h2 className="mt-5 hero-sec-text">Welcome!</h2>
                <h4 className="mt-5">
                  Authenticate the quality of your LPG SKID
                </h4>
              </div>

              <Card className="border-0 mt-5  hero-sec-card  rounded ">
                <Card.Body>
                  <div className="p-4">
                    <p className="">
                      Enter your unique cylinder identification number to verify
                      its authenticity.
                    </p>
                  </div>
                  <div className="my-5 px-3">
                    <Formik
                      initialValues={{
                        product_sn: "",
                      }}
                      validationSchema={Yup.object().shape({
                        product_sn: Yup.string().required(
                          "Serial number is required"
                        ),
                      })}
                      onSubmit={(values) => {
                        dispatch(
                          validateProductAsync({
                            product_sn: values.product_sn,
                            detailed: true,
                          })
                        )
                          .then((response) => {
                            if (response?.payload?.status === "success") {
                              navigate("/product-details");
                            }
                          })
                          .catch((error) => {});
                      }}
                    >
                      {({
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        values,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="SKID ID"
                            className="mb-3 mt-5"
                          >
                            <Form.Control
                              type="text"
                              placeholder="SKID ID"
                              name="product_sn"
                              value={values.product_sn}
                              onChange={handleChange}
                              autoFocus
                            />
                          </FloatingLabel>
                          {errors.product_sn && touched.product_sn ? (
                            <small className="text-danger">
                              {errors.product_sn}
                            </small>
                          ) : null}

                          <div className="">
                            <Button
                              type="submit"
                              className=" my-5 py-3 border-0 px-5"
                              // onClick={() => setModalProductDetails(true)}
                            >
                              <small className="">Continue</small>
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div>
                    <CameraButton />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
      </div>
      <div>
        <Row>
          <Col md={12}>
            <div className=""></div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
export default ProductValidate;
