import { Container, Image, Modal } from "react-bootstrap";

function AppModal(props) {
  return (
    <Container>
      {" "}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.docName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Image src={props?.doc} className="img-fluid" />{" "}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Container>
  );
}
export default AppModal;
