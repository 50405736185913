import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addFeedback,
  getAllFeedbackDetails,
  getAllFeedbackStats,
  getAllSupportDetails,
  getAllSupportStats,
  getFeedbackDetailsById,
  getSupportDetailsById,
  supportForm,
  updateFeedbackStatus,
  updateSupportStatus,
} from "../../services/support-feedback/supportFeedbackService";
import { toast } from "react-toastify";

export const supportFormAsync = createAsyncThunk(
  "support/form",
  async (values) => {
    const response = await supportForm(values);
    return response;
  }
);

export const supportStats = createAsyncThunk("support/stats", async () => {
  const response = await getAllSupportStats();
  return response;
});

export const fetchAllSupport = createAsyncThunk(
  "users/fetchUsers/details",
  async ({ detailed, page }) => {
    const response = await getAllSupportDetails(detailed, page);
    return response;
  }
);

export const fetchSupportById = createAsyncThunk(
  "/support/details/id",
  async ({ ticket_id, detailed }) => {
    const response = await getSupportDetailsById(ticket_id, detailed);
    return response;
  }
);

export const updateSupportStatusById = createAsyncThunk(
  "/support/update/status",
  async ({ id, data }) => {
    const response = await updateSupportStatus(id, data);
    return response;
  }
);

export const fetchAllFeedback = createAsyncThunk(
  "feedback/details",
  async ({ detailed, page, size }) => {
    const response = await getAllFeedbackDetails(detailed, page, size);
    return response;
  }
);
export const fetchAllFeedbackStats = createAsyncThunk(
  "feedback/stats",
  async () => {
    const response = await getAllFeedbackStats();
    return response;
  }
);

export const addFeedbackAsync = createAsyncThunk(
  "feedback/add",
  async (values) => {
    const response = await addFeedback(values);
    return response;
  }
);

export const fetchFeedbackById = createAsyncThunk(
  "/feedback/details/id",
  async ({ feedback_id, detailed }) => {
    const response = await getFeedbackDetailsById(feedback_id, detailed);
    return response;
  }
);

export const updateFeedbackStatusById = createAsyncThunk(
  "/feedback/update/status",
  async ({ id, data }) => {
    const response = await updateFeedbackStatus(id, data);
    return response;
  }
);

const supportSlice = createSlice({
  name: "support",
  initialState: {
    supportFormResponse: {},
    getAllSupportDetailsResponse: {},
    supportStatResponse: {},
    supportByIdResponse: {},
    feedbackByIdResponse: {},
    updateSupportResponse: {},
    updateFeedbackResponse: {},
    feedbackResponse: {},
    feedbackStatsResponse: {},
    addfeedbackResponse: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(supportFormAsync.fulfilled, (state, action) => {
      state.supportFormResponse = action.payload;
      toast.success(action.payload.message);
    });
    builder.addCase(supportFormAsync.rejected, (state, action) => {
      toast.error("Error: Please try again");
    });
    builder.addCase(fetchAllSupport.fulfilled, (state, action) => {
      state.getAllSupportDetailsResponse = action.payload;
    });
    builder.addCase(supportStats.fulfilled, (state, action) => {
      state.supportStatResponse = action.payload;
    });
    builder.addCase(fetchSupportById.fulfilled, (state, action) => {
      state.supportByIdResponse = action.payload;
    });
    builder.addCase(fetchFeedbackById.fulfilled, (state, action) => {
      state.feedbackByIdResponse = action.payload;
    });
    builder.addCase(updateSupportStatusById.fulfilled, (state, action) => {
      state.updateSupportResponse = action.payload;
      toast.success(action.payload.message);
    });

    builder.addCase(updateSupportStatusById.rejected, (state, action) => {
      toast.error("Error: Please try again");
    });

    builder.addCase(updateFeedbackStatusById.fulfilled, (state, action) => {
      state.updateFeedbackResponse = action.payload;
      toast.success(action.payload.message);
    });

    builder.addCase(updateFeedbackStatusById.rejected, (state, action) => {
      toast.error("Error: Please try again");
    });
    builder.addCase(fetchAllFeedback.fulfilled, (state, action) => {
      state.feedbackResponse = action.payload;
    });
    builder.addCase(fetchAllFeedbackStats.fulfilled, (state, action) => {
      state.feedbackStatsResponse = action.payload;
    });
    builder.addCase(addFeedbackAsync.fulfilled, (state, action) => {
      state.addfeedbackResponse = action.payload;
      toast.success(action.payload.message);
    });
    builder.addCase(addFeedbackAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });
  },
});

export default supportSlice.reducer;
