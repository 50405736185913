import { Formik } from "formik";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { MdArrowForwardIos } from "react-icons/md";
import logo from "../../../assests/images/Wooden-heritage-logo-white.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  forgetPasswordAsnyc,
  loginAsync,
} from "../../../slices/auth/authSlice";
import * as Yup from "yup";

function ForgetPassword() {
  const userLogin = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <Row>
        <Col md={7} className="">
          <div className="login-img-side d-sm-none d-none d-md-block  "></div>
        </Col>
        <Col md={5}>
          <Container className="d-flex align-items-center justify-content-center pt-5">
            <Card className="border-0  pt-5 text-center rounded mx-5 login-card shadow">
              <Card.Body>
                <div className="p-4">
                  <h3 className="">Welcome Admin</h3>
                  <p className="">Enter email to reset password</p>
                </div>
                <div className="mt-3 px-3">
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("Invalid email")
                        .required("Email is required"),
                    })}
                    onSubmit={(values) => {
                      dispatch(forgetPasswordAsnyc(values));
                    }}
                    validateOnChange
                    validateOnBlur
                    validateOnSubmit
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                      values,
                      handleChange,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Group>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Email"
                            className="my-3"
                          >
                            <Form.Control
                              type="email"
                              placeholder="Enter your email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            {errors.email && touched.email ? (
                              <div className="text-danger">{errors.email}</div>
                            ) : null}
                          </FloatingLabel>
                        </Form.Group>

                        <div className="d-grid gap-2">
                          <Button
                            type="submit"
                            variant="primary"
                            className=" my-5 py-3 border-0 clearfix text-white"
                          >
                            <small className="float-start ">Submit</small>
                            <MdArrowForwardIos className="float-end" />
                          </Button>
                        </div>
                        <div className="mb-3">
                          <Link
                            to="/login"
                            className=" border border-1 px-5 py-3  rounded"
                          >
                            Login
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
    </div>
  );
}
export default ForgetPassword;
