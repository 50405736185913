import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import {
  TiSocialTwitterCircular,
  TiSocialLinkedinCircular,
  TiSocialYoutubeCircular,
} from "react-icons/ti";

import { CiFacebook } from "react-icons/ci";
import { AiOutlineInstagram } from "react-icons/ai";
function TopNavigation() {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-brown"
        sticky="top"
      >
        <Container>
          <Navbar.Brand href="#home">
            <div>
              <small className="top-nav-text">
                +234 912 000 0601 | info@rokswoodenergy.com
              </small>
              <span className="text-text-lighter ms-4">
                <span className="rounded-pill border-white border-1">
                  <a
                    href="https://www.instagram.com/rokswood_energy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiOutlineInstagram className="text-white mx-2" />
                  </a>
                </span>
                <span className="rounded-pill border-white border-1">
                  <a
                    href="https://twitter.com/RokswoodEnergy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TiSocialTwitterCircular className="text-white mx-2" />
                  </a>
                </span>
                <span className="rounded-pill border-white border-1">
                  <a
                    href="https://www.facebook.com/rokswoodenergy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CiFacebook className="text-white mx-2" />
                  </a>
                </span>
                <span className="rounded-pill border-white border-1">
                  <a
                    href="http://linkedin.com/company/rokswoodenergy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TiSocialLinkedinCircular className="text-white mx-2" />
                  </a>
                </span>
                <span className="rounded-pill border-white border-1">
                  <a
                    href="https://www.youtube.com/channel/UCiHpYFoIJZYqeCm4FSNQjEA"
                    target="_blank"
                    className="text-black"
                    rel="noreferrer"
                  >
                    <TiSocialYoutubeCircular className="text-white mx-2" />
                  </a>
                </span>
              </span>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto"></Nav>

            <Nav>
              <Dropdown>
                <small className="top-nav-text me-3">Select Language</small>
                <Dropdown.Toggle
                  variant="transparent"
                  id="dropdown-basic"
                  className="text-white small"
                >
                  Select Language
                </Dropdown.Toggle>
                <Dropdown.Menu className="bg-dark">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default TopNavigation;
