import { Container, Modal, Table } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";

function ViewFeedbackModal(props) {
  const productFeedback = useSelector(
    (state) => state.products.productFeedbackResponse
  );
  return (
    <Container>
      {" "}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Serial#</th>
                <th>Feedback </th>
                <th>Ratings</th>
              </tr>
            </thead>
            <tbody>
              {productFeedback?.data?.items.map((feed, index) => (
                <tr key={index}>
                  <td> {index + 1} </td>
                  <td> {feed?.reference} </td>
                  <td> {feed?.message} </td>

                  <td>
                    {" "}
                    <AiFillStar className="text-warning" /> {feed?.rating}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
export default ViewFeedbackModal;
