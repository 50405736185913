import { Card, Col, Container, Row } from "react-bootstrap";
import AdminLayout from "../../components/layouts/admin-layout";
import { PiArmchairFill } from "react-icons/pi";
import DateTimeDisplay from "../../util/date";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductStats } from "../../slices/product/productSlice";
import DoughnutChart from "../../components/charts/doughnut";
import LineChart from "../../components/charts/line";

function Dashboard() {
  const dispatch = useDispatch();
  const data = {
    labels: ["Success", "Failed"],
    datasets: [
      {
        label: "Rate",
        data: [60, 40],
        backgroundColor: [" #9ECD2C", "#D13448"],
        borderWidth: 0,
      },
    ],
  };

  const productStats = useSelector(
    (state) => state.products.productStatsResponse
  );
  useEffect(() => {
    try {
      dispatch(getProductStats());
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <AdminLayout>
      <Container>
        <div className="mt-5">
          <h5 className="fw-bold">Dashboard</h5>
          <DateTimeDisplay />
        </div>
        <Row>
          <Col md={3}>
            <Card className="dash-card-1">
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div className="">
                      <h6>
                        Total SKID <br />
                        Produced{" "}
                      </h6>
                      <h6>{productStats?.data?.total} </h6>
                    </div>
                  </Col>
                  <Col>
                    <div className="card-1-icon text-center py-3">
                      <PiArmchairFill className="text-black" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div>
                      <h6 className="fw-bold">
                        SKID
                        <br /> Authenticated{" "}
                      </h6>
                      <h6>{productStats?.data?.is_authentic} </h6>
                    </div>
                  </Col>
                  <Col>
                    <div className="card-2-icon text-center py-3">
                      <PiArmchairFill className="icon" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div>
                      <h6 className="fw-bold">
                        Success <br /> Rate{" "}
                      </h6>
                      <h6>0 Clicks </h6>
                    </div>
                  </Col>
                  <Col>
                    <div className="card-3-icon text-center py-3">
                      <PiArmchairFill className="icon" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div>
                      <h6>
                        Failed <br /> Results{" "}
                      </h6>
                      <h6>{productStats?.data?.not_authentic} </h6>
                    </div>
                  </Col>
                  <Col>
                    <div className="card-4-icon text-center py-3">
                      <PiArmchairFill className="icon" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div>
                      <h6 className="fw-bold">
                        Website <br /> Traffic{" "}
                      </h6>
                      <h6 className="fw-bold">0988 </h6>
                    </div>
                  </Col>
                  <Col>
                    <div className="card-5-icon text-center py-3">
                      <PiArmchairFill className="icon" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card className="mt-5 border-1 shadow">
              <Card.Body>
                <h5>Success Rate</h5>
                <div className="chart-section">
                  <DoughnutChart data={data} />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={8}>
            <Card className="mt-5 border-1 shadow">
              <Card.Body>
                <h5> Authentication</h5>
                <div className="chart-section">
                  <LineChart />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </AdminLayout>
  );
}
export default Dashboard;
