import SideBar from "../sidebar/sidebar";

function AdminLayout({ children }) {
  return (
    <div className="real">
      <div className="cont-div">
        <SideBar />{" "}
      </div>
      <main className="content">{children}</main>
    </div>
  );
}
export default AdminLayout;
