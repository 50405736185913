import { Formik } from "formik";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import * as Yup from "yup";
import { addUser } from "../../../../slices/user/userSlice";

function AddUser(props) {
  const roles = useSelector((state) => state.data?.roles?.data);
  const positions = useSelector((state) => state.data?.position?.data);
  const departments = useSelector((state) => state.data?.departments?.data);
  const [profilePic, setProfilePic] = useState();
  const [imageUrl, setImageUrl] = useState(null);

  const dispatch = useDispatch();
  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (!file.type.startsWith("image/")) {
      toast.info("Please upload an image file.");
      return;
    }

    const imgInKb = file.size / 1024;

    if (imgInKb > 500) {
      toast.info("Image size must be 500kb or less");

      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);
    setImageUrl(URL.createObjectURL(file));

    reader.onloadend = () => {
      // const profileImg = {};
      // profileImg["file"] = file;
      // console.log("first", file);
      // profileImg["img"] = reader.result;

      setProfilePic(reader.result);
    };
  };
  return (
    <>
      <Container>
        {" "}
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                appointment_date: "",
                department: "",
                email: "",
                id_card_number: "",
                firstname: "",
                lastname: "",
                phone: "",
                position: "",
                role: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email")
                  .required("Email is required"),
                department: Yup.string()
                  .required("Department is required.")
                  .min(1, "Please select from the options"),
                appointment_date: Yup.string().required(
                  "Appointment date is required."
                ),
                firstname: Yup.string().required("First name is required."),
                lastname: Yup.string().required("Last name is required."),
                phone: Yup.string().required("Phone is required."),
                position: Yup.string()
                  .required("Position is required.")
                  .min(1, "Please select from the options"),
                role: Yup.string()
                  .required("Role is required.")
                  .min(1, "Please select from the options"),
                id_card_number: Yup.string().required(
                  "ID card number is required."
                ),
              })}
              onSubmit={(values) => {
                const payload = {
                  ...values,
                  image: profilePic,
                };
                dispatch(addUser(payload));
                props.closeAddModal();
              }}
            >
              {({ errors, touched, values, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={3}>
                      <Card>
                        <Card.Body>
                          <div className="">
                            <div className="upload-picture-sec">
                              {imageUrl && (
                                <Image
                                  src={imageUrl}
                                  width={100}
                                  className=" upload-picture-sec"
                                />
                              )}
                            </div>
                            <div class="fileUpload btn btn-light my-3">
                              <label class="upload">
                                <Form.Control
                                  type="file"
                                  accept="image/*"
                                  placeholder=""
                                  onChange={handleImageUpload}
                                />
                                <small>Upload Image</small>
                              </label>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="First Name"
                          className="my-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            name="firstname"
                            value={values.firstname}
                            onChange={handleChange}
                          />
                          {errors.firstname && touched.firstname ? (
                            <small className="text-danger">
                              {errors.firstname}
                            </small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Email"
                          className="my-3"
                        >
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                          {errors.email && touched.email ? (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Phone Number"
                          className="my-3"
                        >
                          <Form.Control
                            type="tel"
                            placeholder="Phone Number"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                          />
                          {errors.phone && touched.phone ? (
                            <small className="text-danger">
                              {errors.phone}
                            </small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="ID Card Number"
                          className="my-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="ID Card Number"
                            name="id_card_number"
                            value={values.id_card_number}
                            onChange={handleChange}
                          />
                          {errors.id_card_number && touched.id_card_number ? (
                            <small className="text-danger">
                              {errors.id_card_number}
                            </small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Date of Appointment"
                          className="my-3"
                        >
                          <Form.Control
                            type="date"
                            placeholder="Date of Appointment"
                            name="appointment_date"
                            value={values.appointment_date}
                            onChange={handleChange}
                          />
                          {errors.appointment_date &&
                          touched.appointment_date ? (
                            <small className="text-danger">
                              {errors.appointment_date}
                            </small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Last Name"
                          className="my-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            name="lastname"
                            value={values.lastname}
                            onChange={handleChange}
                          />
                          {errors.lastname && touched.lastname ? (
                            <small className="text-danger">
                              {errors.lastname}
                            </small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Department"
                          className="my-3"
                        >
                          <Form.Select
                            name="department"
                            aria-label="Floating label select example"
                            value={values.department}
                            onChange={handleChange}
                          >
                            <option value="">Select</option>
                            {departments?.departments?.map(
                              (department, index) => (
                                <option key={index} value={department}>
                                  {department}
                                </option>
                              )
                            )}
                          </Form.Select>

                          {errors.department && touched.department ? (
                            <small className="text-danger">
                              {errors.department}
                            </small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Position"
                          className="my-3"
                        >
                          <Form.Select
                            name="position"
                            aria-label="Floating label select example"
                            value={values.position}
                            onChange={handleChange}
                          >
                            <option value="">Select</option>
                            {positions?.positions?.map((position, index) => (
                              <option key={index} value={position}>
                                {position}
                              </option>
                            ))}
                          </Form.Select>
                          {errors.position && touched.position ? (
                            <small className="text-danger">
                              {errors.position}
                            </small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Role"
                          className="my-3"
                        >
                          <Form.Select
                            name="role"
                            aria-label="Floating label select example"
                            value={values.role}
                            onChange={handleChange}
                          >
                            <option value="">Select</option>
                            {roles?.roles?.map((role, index) => (
                              <option key={index} value={role}>
                                {role}
                              </option>
                            ))}
                          </Form.Select>
                          {errors.role && touched.role ? (
                            <small className="text-danger">{errors.role}</small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className=" clearfix">
                    <Button
                      type="submit"
                      className=" btn-2-color px-3 border-0 float-end me-5"
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}
export default AddUser;
