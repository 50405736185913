import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import AdminLayout from "../../../components/layouts/admin-layout";
import SupportModal from "./modals/support-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllFeedback,
  fetchAllFeedbackStats,
  fetchAllSupport,
  fetchFeedbackById,
  fetchSupportById,
  supportStats,
} from "../../../slices/support-feedback/supportFeedbackSlice";
import moment from "moment";
import DateTimeDisplay from "../../../util/date";
import { AiFillStar } from "react-icons/ai";
import FeedbackModal from "./modals/feedback-modal";

function FeedbackAndSupport() {
  const dispatch = useDispatch();
  const [modalSupport, setModalSupport] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);
  const supports = useSelector(
    (state) => state.support?.getAllSupportDetailsResponse?.data
  );
  const suportStats = useSelector(
    (state) => state.support?.supportStatResponse?.data
  );

  const feedbacks = useSelector(
    (state) => state.support?.feedbackResponse?.data
  );

  const feedbackStats = useSelector(
    (state) => state.support?.feedbackStatsResponse?.data
  );

  const handleViewSupport = (id) => {
    dispatch(fetchSupportById({ ticket_id: id, detailed: true }));
    setModalSupport(true);
  };

  const handleViewFeedback = (id) => {
    dispatch(fetchFeedbackById({ feedback_id: id, detailed: true }));
    setModalFeedback(true);
  };
  const closeSupportModal = () => {
    setModalSupport(false);
  };
  const closeFeedbackModal = () => {
    setModalFeedback(false);
  };
  useEffect(() => {
    try {
      dispatch(fetchAllSupport({ detailed: true, page: "all" }));
      dispatch(fetchAllFeedback({ detailed: false, page: 1, size: 10 }));
      dispatch(supportStats());
      dispatch(fetchAllFeedbackStats());
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <AdminLayout>
      <SupportModal
        show={modalSupport}
        onHide={() => setModalSupport(false)}
        closeSupportModal={closeSupportModal}
      />
      <FeedbackModal
        show={modalFeedback}
        onHide={() => setModalFeedback(false)}
        closeFeedbackModal={closeFeedbackModal}
      />
      <Container>
        <div className="mt-5">
          <h5 className="fw-bold">Feedback & Support</h5>
          <DateTimeDisplay />
        </div>
      </Container>{" "}
      <Tabs
        defaultActiveKey="feedback"
        id="justify-tab-example"
        className="mb-3 mt-5"
      >
        <Tab eventKey="feedback" title="Feedback">
          <div className="my-5">
            <Row>
              <Col md={5} className="p-0 m-0">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Filter By Date"
                  className=""
                  variant="light"
                  as={ButtonGroup}
                >
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
              <Col md={7}>
                <div>
                  <Button variant="outline-success" className="p-2 me-3">
                    Read{" "}
                    <Badge pill bg="success">
                      {feedbackStats?.is_read}
                    </Badge>
                  </Button>
                  <Button variant="outline-danger" className="p-2">
                    Unread{" "}
                    <Badge pill bg="danger">
                      {feedbackStats?.unread}
                    </Badge>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <Card>
            <Card.Body>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Serial#</th>
                    <th>Feedback </th>
                    <th>Status</th>
                    <th>Ratings</th>
                  </tr>
                </thead>
                <tbody>
                  {feedbacks?.items?.map((feed, index) => (
                    <tr key={index}>
                      <td> {index + 1} </td>
                      <td> {feed?.reference} </td>
                      <td> {feed?.message} </td>
                      <td>
                        {feed.is_read === true ? (
                          <Button
                            variant="outline-success"
                            className="px-2  rounded "
                            size="sm"
                          >
                            Unread{" "}
                          </Button>
                        ) : (
                          <Button
                            variant="outline-success"
                            className="px-2  rounded "
                            size="sm"
                          >
                            Read{" "}
                          </Button>
                        )}
                      </td>
                      <td>
                        {" "}
                        <AiFillStar className="text-warning" /> {feed?.rating}{" "}
                      </td>
                      <td>
                        {" "}
                        <Button
                          variant="light"
                          onClick={() => handleViewFeedback(feed.id)}
                        >
                          View
                        </Button>{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Tab>
        <Tab eventKey="support" title="Support">
          <div className="my-5">
            <Row>
              <Col md={5} className="p-0 m-0">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Filter By Date"
                  className=""
                  variant="light"
                  as={ButtonGroup}
                >
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
              <Col md={7}>
                <div>
                  <Button variant="outline-success" className="p-2 me-3">
                    Replied{" "}
                    <Badge pill bg="success">
                      {suportStats?.is_closed}
                    </Badge>
                  </Button>
                  <Button variant="outline-danger" className="p-2">
                    Unread{" "}
                    <Badge pill bg="danger">
                      {suportStats?.is_open}
                    </Badge>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <Card>
            <Card.Body>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone Number </th>
                    <th>Email </th>
                    <th>Request Date</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {supports?.items?.map((support, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td> {support?.customer_name} </td>
                      <td> {support?.customer_phone} </td>
                      <td> {support?.customer_email} </td>
                      <td> {moment(support?.created_at).format("LL")} </td>
                      <td>
                        {" "}
                        {support?.is_closed === true ? (
                          <div>
                            <small>Replied</small>
                          </div>
                        ) : (
                          <small>Pending</small>
                        )}{" "}
                      </td>
                      <td>
                        {" "}
                        <Button
                          variant="light"
                          onClick={() => handleViewSupport(support.id)}
                        >
                          View
                        </Button>{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>{" "}
    </AdminLayout>
  );
}
export default FeedbackAndSupport;
