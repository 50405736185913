import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
  Table,
} from "react-bootstrap";
import AdminLayout from "../../../components/layouts/admin-layout";
import { IoMdAddCircle } from "react-icons/io";
import AddProductModal from "./modals/add-product";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getProductById,
  getProductSerialNumber,
} from "../../../slices/product/productSlice";
import moment from "moment";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import EditProductModal from "./modals/edit-product";
import ViewProductModal from "./modals/view-product";
import { toast } from "react-toastify";
import DateTimeDisplay from "../../../util/date";
import queryString from "query-string";
import PaginationComponent from "../../../util/pagination";

function CylinderManagement() {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.products.allProductsResponse);

  const productStats = useSelector(
    (state) => state.products.productStatsResponse
  );

  const [modalAddProduct, setModalAddProduct] = useState();
  const [modalEditProduct, setModalEditProduct] = useState();
  const [modalViewProduct, setModalViewProduct] = useState();
  const [filterCategory, setFilterCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [dataToUpdate, setDataToUpdate] = useState([]);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const closeAddModal = () => {
    setModalAddProduct(false);
  };
  const closeEditModal = () => {
    setModalEditProduct(false);
  };

  const handleAddProduct = () => {
    setModalAddProduct(true);
    dispatch(getProductSerialNumber({}));
  };
  const handleEdit = (id) => {
    dispatch(getProductById({ id: id, detailed: true }));
    setModalEditProduct(true);
    const dataToUpdate = products?.data?.items?.filter(
      (data) => data.id === id
    );
    setDataToUpdate(dataToUpdate);
  };
  const handleView = async (id, detailed) => {
    setModalViewProduct(true);

    try {
      await dispatch(getProductById({ id, detailed: true }));
    } catch (error) {
      toast.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    const categories = new Set();

    products?.data?.items.forEach((obj) => {
      categories.add(obj.category?.name);
    });

    const arrayCategory = [...categories];
    setFilterCategory(arrayCategory);
  }, [products]);

  useEffect(() => {
    const parsedQuery = queryString.parse(window.location.search);
    const pageParam = parseInt(parsedQuery.page) || 1;
    const sizeParam = parseInt(parsedQuery.size) || 10;

    dispatch(
      getAllProducts({ detailed: true, page: pageParam, size: sizeParam })
    );
    setPage(pageParam);
    setSize(sizeParam);
  }, [window.location.search]);

  const handlePageChange = (newPage) => {
    const maxPage = Math.ceil(products.length / size);

    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > maxPage) {
      newPage = maxPage;
    }

    dispatch(getAllProducts({ detailed: true, page: newPage, size }));

    const newQuery = queryString.stringify({
      ...queryString.parse(window.location.search),
      page: newPage,
    });
    window.history.pushState(null, "", `?${newQuery}`);
  };

  const maxPage = Math.ceil(products.length / size);
  return (
    <AdminLayout>
      <AddProductModal
        show={modalAddProduct}
        onHide={() => setModalAddProduct(false)}
        closeAddModal={closeAddModal}
      />
      <EditProductModal
        show={modalEditProduct}
        onHide={() => setModalEditProduct(false)}
        closeAddModal={closeEditModal}
        data={dataToUpdate}
      />
      <ViewProductModal
        show={modalViewProduct}
        onHide={() => setModalViewProduct(false)}
      />

      <Container>
        <div className="mt-5">
          <h5 className="fw-bold">SKID Management</h5>
          <DateTimeDisplay />
        </div>
        <div className="my-5">
          <Row>
            <Col md={5} className="p-0 m-0">
              <DropdownButton
                as={ButtonGroup}
                id="dropdown-basic-button"
                title="Filter By Category"
                className="me-1"
                variant="light"
              >
                <Dropdown.Item onClick={() => setSelectedCategory(null)}>
                  All
                </Dropdown.Item>
                {filterCategory?.map((category, index) => (
                  <Dropdown.Item
                    key={index}
                    value={category}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {" "}
                    <small> {category}</small>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
            <Col md={7}>
              <div>
                <Button variant="outline-success" className="p-2 me-3">
                  In Use{" "}
                  <Badge pill bg="success">
                    {productStats?.data?.is_authentic}
                  </Badge>
                </Button>
                <Button variant="outline-danger" className="p-2">
                  Expired{" "}
                  <Badge pill bg="danger">
                    {productStats?.data?.not_authentic}
                  </Badge>
                </Button>
                <Button
                  variant="light"
                  className="p-2 ms-2"
                  onClick={() => handleAddProduct()}
                >
                  <IoMdAddCircle className="text-success " /> Add SKID
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Card>
          <Card.Body>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Serial#</th>
                  <th>Manufacture Date</th>
                  <th>Expiry Date</th>
                  <th>Thickness</th>
                  <th>Volume</th>
                  <th>Weight</th>
                  <th>Color</th>
                  <th>Allowable Pressure</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products?.data?.items?.map((product, index) => {
                  if (
                    selectedCategory &&
                    product?.category?.name !== selectedCategory
                  ) {
                    return null;
                  }
                  return (
                    <tr key={index}>
                      <td> {index + 1} </td>
                      <td> {product?.serial_number} </td>
                      <td>
                        {" "}
                        {moment(product?.manufacture_date).format("LL")}{" "}
                      </td>
                      <td> {moment(product?.expiry_date).format("LL")} </td>
                      <td> {product?.thickness} </td>
                      <td> {product?.volume} </td>
                      <td> {product?.weight} </td>
                      <td
                        style={
                          product?.color
                            ? {
                                backgroundColor: product?.color,
                              }
                            : {}
                        }
                      >
                        {" "}
                      </td>
                      <td> {product?.allowable_pressure} </td>

                      <td>
                        {" "}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="light"
                            className=" border-0"
                          >
                            <BiDotsHorizontalRounded className="fw-bold " />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleEdit(product.id)}
                              className="small"
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleView(product.id)}
                              className="small"
                            >
                              View
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <div>
          <PaginationComponent
            currentPage={page}
            totalPages={maxPage}
            onPageChange={handlePageChange}
          />
        </div>
      </Container>
    </AdminLayout>
  );
}
export default CylinderManagement;
