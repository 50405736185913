import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import originalIconBig from "../../../../assests/images/original-img-big.png";
import logo from "../../../../assests/images/R-Energy-logo.png";
import { RiFeedbackFill } from "react-icons/ri";
import { AiOutlinePrinter } from "react-icons/ai";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ViewFeedbackModal from "./view-feedback-modal";
import { getProductFeedback } from "../../../../slices/product/productSlice";
import AppModal from "../../../../components/modals/modal";
import moment from "moment";

function ViewProductModal(props) {
  const dispatch = useDispatch();
  const [modalShowDoc, setModalShowDoc] = useState(false);
  const [modalViewFeedback, setModalViewFeedback] = useState();

  const productDetails = useSelector(
    (state) => state.products.getProductByIdResponse
  );
  const serial_number = productDetails?.data?.id;

  const handleViewFeedback = (productId) => {
    dispatch(getProductFeedback({ productId: serial_number }));
    setModalViewFeedback(true);
  };

  const productFeatures = productDetails?.data?.features;

  const featureArray = productFeatures ? Object.entries(productFeatures) : [];

  return (
    <>
      <ViewFeedbackModal
        show={modalViewFeedback}
        onHide={() => setModalViewFeedback(false)}
      />
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="">
          <>
            <Modal.Title className="me-5">
              {" "}
              {productDetails?.data?.serial_number}{" "}
            </Modal.Title>
          </>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <Row>
              <Col md={4}>
                <div>
                  <Image
                    src={productDetails?.data?.image}
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-5">
                  <small>Cylinder Serial Number</small>
                  <h6>{productDetails?.data?.serial_number}</h6>
                </div>
                <Row>
                  <Col md={6}>
                    <div className="mb-5">
                      <small>Manufacture Date</small>
                      <h6>
                        {moment(productDetails?.data?.manufacture_date).format(
                          "LL"
                        )}
                      </h6>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-5">
                      <small className="text-danger">Expire Date</small>
                      <h6>
                        {moment(productDetails?.data?.expiry_date).format("LL")}
                      </h6>
                    </div>
                  </Col>
                  <div className="mb-5">
                    <small>Pressure Test Certificate</small>
                    <h6>
                      <a
                        href={productDetails?.data?.pressure_test_certificate}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Pressure Test Certificate
                      </a>
                    </h6>
                  </div>
                </Row>
              </Col>
              <Col md={4}>
                {" "}
                <div>
                  <Image
                    src={productDetails?.data?.qrcode}
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div>
            <Row>
              <Col md={12} className="">
                <Card className="rounded-0 bg-warning-subtle border-0">
                  <Card.Body>
                    <h5>Technical Parameters</h5>
                    <Row>
                      <Col md={3}>
                        <div className="mb-5">
                          <small>Thickness</small>
                          <h6>{productDetails?.data?.thickness}</h6>
                        </div>
                        <div className="mb-5">
                          <small>Color </small>
                          <h6>{productDetails?.data?.color}</h6>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-5">
                          <small>Volume </small>
                          <h6>{productDetails?.data?.volume}</h6>
                        </div>
                        <div className="mb-5">
                          <small>Allowable Pressure </small>
                          <h6>{productDetails?.data?.allowable_pressure}</h6>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-5">
                          <small>Weight </small>
                          <h6>{productDetails?.data?.weight}</h6>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mt-5 text-center">
                          <Image src={originalIconBig} width={120} />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          <div className="my-5">
            <Row>
              <Col md={8}>
                <div className="mt-5">
                  {" "}
                  <Image src={logo} />{" "}
                  <span>
                    {" "}
                    <small className="">
                      Copyright © {new Date().getFullYear()} Rokswood Energy
                    </small>
                  </span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-5 pt-5 float-end">
                  <Button
                    variant="primary rounded-0"
                    onClick={() => handleViewFeedback(serial_number)}
                  >
                    {" "}
                    <RiFeedbackFill /> View Feedback
                  </Button>{" "}
                  <Button variant="light" className="light border-secondary">
                    <AiOutlinePrinter />
                    Print
                  </Button>{" "}
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
export default ViewProductModal;
