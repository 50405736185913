import { Container, Image, Nav, Navbar } from "react-bootstrap";
import logo from "../../assests/images/R-Energy-logo.png";
import { MdOutlineSupportAgent } from "react-icons/md";
import FQA from "../offcanvas/fqa";
import { useState } from "react";
import Support from "../offcanvas/support";

function Navigation() {
  const [modalFQA, setModalFQA] = useState(false);
  const [modalSupport, setModalSupport] = useState(false);

  return (
    <>
      <FQA
        show={modalFQA}
        onHide={() => setModalFQA(false)}
        placement={"end"}
      />
      <Support
        show={modalSupport}
        onHide={() => setModalSupport(false)}
        placement={"end"}
      />
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary"
        sticky="top"
      >
        <Container>
          <Navbar.Brand href="#home">
            <Image src={logo} width={60} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto"></Nav>

            <Nav className="">
              <Nav.Link
                href="#"
                className="mx-2"
                onClick={() => setModalFQA(true)}
              >
                FQAs
              </Nav.Link>

              <Nav.Link
                href="#"
                className="mx-2"
                onClick={() => setModalSupport(true)}
              >
                <MdOutlineSupportAgent /> Support
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default Navigation;
