import moment from "moment";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFeedbackStatusById,
  updateSupportStatusById,
} from "../../../../slices/support-feedback/supportFeedbackSlice";

function FeedbackModal(props) {
  const dispatch = useDispatch();

  const feedbackDetails = useSelector(
    (state) => state.support?.feedbackByIdResponse?.data
  );

  const handleManage = (id) => {
    dispatch(
      updateFeedbackStatusById({
        id: id,
        data: {
          is_read: true,
        },
      })
    );
    props.closeFeedbackModal(false);
  };

  return (
    <Container>
      {" "}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            Feedback - {feedbackDetails?.reference}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={6}>
                <div className="mb-5">
                  <small> SKID</small>
                  <h6> {feedbackDetails?.reference} </h6>
                </div>
                <div className="mb-5">
                  <small> Manufacture Date</small>
                  <h6>
                    {moment(feedbackDetails?.product?.manufacture_date).format(
                      "LL"
                    )}
                  </h6>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-5">
                  <small> Expiry date</small>
                  <h6>
                    {moment(feedbackDetails?.product?.expiry_date).format("LL")}
                  </h6>
                </div>

                <div className="mb-5">
                  <small> Serial Number</small>
                  <h6>{feedbackDetails?.product?.serial_number}</h6>
                </div>
              </Col>
            </Row>
            <hr />
            <div className="my-5">
              <h6 className="fw-bold">Message</h6>
              <p>{feedbackDetails?.message}</p>
            </div>
          </Container>
          <div className="my-3">
            {feedbackDetails?.is_read ? null : (
              <Button
                variant="outline-success"
                className="p-2 me-3"
                onClick={() => handleManage(feedbackDetails.id)}
                disabled={feedbackDetails?.is_read ? true : false}
              >
                Mark as Read{" "}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
export default FeedbackModal;
