import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";

import { AiOutlineCloudUpload } from "react-icons/ai";

import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../../../../slices/product/productSlice";

function EditProductModal(props) {
  const [pressureTestCertificate, setPressureTestCertificate] = useState(null);
  const [productImage, setProductImage] = useState(null);

  const dispatch = useDispatch();
  const productDetails = useSelector(
    (state) => state.products.getProductByIdResponse
  );
  const productId = productDetails?.data?.id;
  const handleImageUpload = (e, inputIndex) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      if (!file.type.startsWith("image/")) {
        toast.info(`Skipping ${file.name}: Please upload an image file.`);
        return;
      }

      const imgInKb = file.size / 1024;

      if (imgInKb > 500) {
        toast.info(`Skipping ${file.name}: Image size must be 500kb or less.`);
        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        const imageBase64 = reader.result;

        switch (inputIndex) {
          case 1:
            setProductImage(imageBase64);
            break;
          case 2:
            setPressureTestCertificate(imageBase64);
            break;

          default:
            break;
        }

        toast.success(`${file.name} uploaded successfully.`);
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="">
          <>
            <Modal.Title className="me-5"> Edit SKID</Modal.Title>
          </>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              allowable_pressure: props?.data[0]?.allowable_pressure,
              color: props?.data[0]?.color,
              expiry_date: props?.data[0]?.expiry_date,
              image: props?.data[0]?.image,
              manufacture_date: props?.data[0]?.manufacture_date,
              thickness: props?.data[0]?.thickness,
              volume: props?.data[0]?.volume,
              weight: props?.data[0]?.weight,
              serial_number: props?.data[0]?.serial_number,
            }}
            validationSchema={Yup.object().shape({})}
            onSubmit={(values) => {
              const payload = {
                ...values,
                pressure_test_certificate: pressureTestCertificate,
                image: productImage,
              };
              dispatch(updateProduct({ id: productId, payload }));
              props.closeAddModal();
            }}
          >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={3} className="me-0 pe-0">
                    <Card className="rounded-0">
                      <Card.Body>
                        <div className="">
                          {values.image ? (
                            <Image
                              src={productImage || values.image}
                              width={100}
                              className="rounded-profile"
                            />
                          ) : null}

                          <div className="fileUpload btn btn-light my-3 text-center">
                            <label className="upload">
                              <Form.Control
                                type="file"
                                accept="image/*"
                                placeholder=""
                                onChange={(e) => handleImageUpload(e, 1)}
                              />
                              <small>
                                <AiOutlineCloudUpload /> Update Product Image
                              </small>
                            </label>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={9}>
                    <div className="">
                      <Form.Group>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Cylinder Serial Number"
                          className="my-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Cylinder Serial Number"
                            name="serial_number"
                            value={values.serial_number}
                            onChange={handleChange}
                            disabled
                          />
                          {errors.serial_number && touched.serial_number ? (
                            <small className="text-danger">
                              {errors.serial_number}
                            </small>
                          ) : null}
                        </FloatingLabel>
                      </Form.Group>
                    </div>

                    <Row>
                      <Col md={6}>
                        <div className="mb-5">
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Expire Date"
                              className="my-3"
                            >
                              <Form.Control
                                type="date"
                                placeholder="Expire Date"
                                name="expiry_date"
                                value={values.expiry_date}
                                onChange={handleChange}
                              />
                              {errors.expiry_date && touched.expiry_date ? (
                                <small className="text-danger">
                                  {errors.expiry_date}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-5">
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Manufacture Date"
                              className="my-3"
                            >
                              <Form.Control
                                type="date"
                                placeholder="Manufacture Date"
                                name="manufacture_date"
                                value={values.manufacture_date}
                                onChange={handleChange}
                              />
                              {errors.manufacture_date &&
                              touched.manufacture_date ? (
                                <small className="text-danger">
                                  {errors.manufacture_date}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div>
                  <Row>
                    <Col md={12} className="me-0 pe-0">
                      <Card className="rounded-0 border-0">
                        <Card.Body>
                          <h5>Technical Parameters</h5>
                          <Row>
                            <Col md={6}>
                              <div className="mb-5">
                                <Form.Group>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Thickness"
                                    className="my-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Thickness"
                                      name="thickness"
                                      value={values.thickness}
                                      onChange={handleChange}
                                    />
                                    {errors.thickness && touched.thickness ? (
                                      <small className="text-danger">
                                        {errors.thickness}
                                      </small>
                                    ) : null}
                                  </FloatingLabel>
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <Form.Group>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Volume"
                                  className="my-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Volume"
                                    name="volume"
                                    value={values.volume}
                                    onChange={handleChange}
                                  />
                                  {errors.volume && touched.volume ? (
                                    <small className="text-danger">
                                      {errors.volume}
                                    </small>
                                  ) : null}
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="mb-5">
                                <Form.Group>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Weight"
                                    className="my-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Weight"
                                      name="weight"
                                      value={values.weight}
                                      onChange={handleChange}
                                    />
                                    {errors.weight && touched.weight ? (
                                      <small className="text-danger">
                                        {errors.weight}
                                      </small>
                                    ) : null}
                                  </FloatingLabel>
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <Form.Group>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Colour"
                                  className="my-3"
                                >
                                  <Form.Control
                                    className="w-100"
                                    type="color"
                                    placeholder="Colour"
                                    name="color"
                                    value={values.color}
                                    onChange={handleChange}
                                  />
                                  {errors.color && touched.color ? (
                                    <small className="text-danger">
                                      {errors.color}
                                    </small>
                                  ) : null}
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="mb-5">
                                <Form.Group>
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Allowable Pressure"
                                    className="my-3"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Allowable Pressure"
                                      name="allowable_pressure"
                                      value={values.allowable_pressure}
                                      onChange={handleChange}
                                    />
                                    {errors.allowable_pressure &&
                                    touched.allowable_pressure ? (
                                      <small className="text-danger">
                                        {errors.allowable_pressure}
                                      </small>
                                    ) : null}
                                  </FloatingLabel>
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <Form.Group>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Upload Pressure Test Certificate"
                                  className="my-3"
                                >
                                  <Form.Control
                                    type="file"
                                    placeholder="Upload Pressure Test Certificate"
                                    name="name"
                                    value={values.name}
                                    onChange={(e) => handleImageUpload(e, 2)}
                                  />
                                  {errors.name && touched.name ? (
                                    <small className="text-danger">
                                      {errors.name}
                                    </small>
                                  ) : null}
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <div className=" my-5">
                  <Button
                    variant="primary"
                    type="submit"
                    className=" px-5 border-0 me-5"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
export default EditProductModal;
