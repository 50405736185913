import {
  DeleteRequest,
  GetRequest,
  PostRequest,
  PutRequest,
} from "../../util/apiMethods";

export const getAllUsers = async (detailed, page, size) => {
  const response = await GetRequest(
    `/users?detailed=${detailed}?page=${page}&size=${size}`
  );
  return response;
};

export const getAllUsersDetails = async (detailed, page, size) => {
  const response = await GetRequest(
    `/users?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const createUser = async (body) => {
  const response = await PostRequest("/users", body);
  return response;
};

export const getUserById = async (id, detailed) => {
  const response = await GetRequest(`/users/${id}?detailed=${detailed}`);
  return response;
};

export const updateUser = async (id, body) => {
  const response = await PutRequest(`/users/${id}`, body);
  return response;
};

export const viewUserActivityLog = async (id, page) => {
  const response = await GetRequest(`/users/${id}/activity_log?page=${page}`);
  return response;
};

export const deleteUser = async (id) => {
  const response = await DeleteRequest(`/users/${id}`);
  return response;
};
export const activateUser = async (id) => {
  const response = await PutRequest(`/users/${id}/activate`);
  return response;
};

export const deactivateUser = async (id) => {
  const response = await PutRequest(`/users/${id}/deactivate`);
  return response;
};
