import { Accordion, Button, Col, Offcanvas, Row } from "react-bootstrap";
import { AiTwotoneMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";

function FQA(props) {
  const fqas = [
    {
      id: 1,
      question: "How can I authenticate my cylinder?",
      answer: "Text here",
    },
    {
      id: 2,
      question: "Where can I find my cylinder authentication number?",
      answer: "Text here",
    },
    {
      id: 3,
      question: "Why do I need to authenticate my cylinder?",
      answer: "Text here",
    },
    {
      id: 4,
      question: "What happens if my cylinder expires?",
      answer: "Text here",
    },
    {
      id: 5,
      question: "Where can I make purchase of your cylinder?",
      answer: "Text here",
    },
  ];
  return (
    <div>
      <Offcanvas {...props} className="offcanvas-size-xl">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>FAQs</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <Accordion defaultActiveKey="0" flush>
              {fqas.map((fqa, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header> {fqa.question} </Accordion.Header>
                  <Accordion.Body>{fqa.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="fqa-footer-section p-3">
            <p>
              {" "}
              If none of the above answers my question, please contact support
              for further enquiry and support needed, Our support is always
              eager and ready to help you out.
            </p>
            <Row>
              <Col md={6}>
                <div>
                  <small>
                    {" "}
                    <BsFillTelephoneFill /> Call Only
                  </small>
                  <h6>+234 904 664 8867</h6>
                </div>
                <div>
                  <small>
                    {" "}
                    <BsFillTelephoneFill /> Whatsapp / Call
                  </small>
                  <h6>+234 912 000 0601</h6>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <small>
                    {" "}
                    <AiTwotoneMail /> Email Support
                  </small>
                  <h6>info@rokswoodenergy.com</h6>
                </div>
              </Col>
            </Row>
            <p>Or</p>
            <div className="my-3">
              <Button className="  px-3 border-0">Fill Contact Form</Button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
export default FQA;
