import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slices/auth/authSlice";
import dataSlice from "../slices/data/dataSlice";
import usersSlice from "../slices/user/userSlice";
import supportFeedbackSlice from "../slices/support-feedback/supportFeedbackSlice";
import productSlice from "../slices/product/productSlice";

const initialLoadingState = localStorage.getItem("loading") === "true";

export default configureStore({
  reducer: {
    users: usersSlice,
    auth: authSlice,
    data: dataSlice,
    products: productSlice,
    support: supportFeedbackSlice,
    loading: (state = initialLoadingState, action) => {
      if (action.type.endsWith("/pending")) {
        localStorage.setItem("loading", "true");
        return true;
      } else if (
        action.type.endsWith("/fulfilled") ||
        action.type.endsWith("/rejected")
      ) {
        // Remove loading state from localStorage
        localStorage.removeItem("loading");
        return false;
      }
      return state;
    },
  },
});
