import Navigation from "../navbar/navigation";
import TopNavigation from "../navbar/top-navigation";
import Footer from "../footer/footer";

function Layout({ children }) {
  return (
    <div>
      <TopNavigation />
      <Navigation />
      <div> {children} </div>
      <Footer />
    </div>
  );
}
export default Layout;
