import { Dropdown, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assests/images/R-Energy-logo.png";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillHome } from "react-icons/ai";
import { HiUsers } from "react-icons/hi";
import { PiCylinderFill } from "react-icons/pi";
import { MdSupportAgent } from "react-icons/md";
import { BiSolidUserRectangle } from "react-icons/bi";
import { logoutAsync } from "../../slices/auth/authSlice";
import { useDispatch } from "react-redux";

function SideBar() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  let loginUser = null;

  if (user) {
    try {
      loginUser = JSON.parse(user);
    } catch (error) {
      console.error("Error parsing 'user' from localStorage:", error);
      // Handle the parsing error, e.g., set loginUser to a default value
    }
  }
  const handleLogout = async () => {
    dispatch(logoutAsync());

    await localStorage.removeItem("token");
    await localStorage.removeItem("user");
    navigate("/login");
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <>
      <aside
        className="sidebar shadow"
        style={{ left: showMenu ? "0" : "-390px" }}
      >
        <div className="bg-white py-3 text-center mb-5 pt-5">
          <Image src={logo} width={70} />
        </div>
        <div className="sidebar-toggle shadow" id="m-tog" onClick={toggleMenu}>
          <div className="text-center mt-1 p-1 text-white h3">
            <GiHamburgerMenu />
          </div>
        </div>

        <Link
          to="/home"
          className={
            location.pathname === "/home"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2 fw-bold">
            <AiFillHome className="sidebar-icon" />
          </span>
          Dashboard
        </Link>
        <Link
          to="/cylinders"
          className={
            location.pathname === "/cylinders"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <PiCylinderFill className="sidebar-icon" />
          </span>
          SKID Management
        </Link>
        <Link
          to="/feedback-support"
          className={
            location.pathname === "/feedback-support"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <MdSupportAgent className="sidebar-icon" />
          </span>
          Feedback & Support
        </Link>
        <Link
          to="/users"
          className={
            location.pathname === "/users"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <HiUsers className="sidebar-icon" />
          </span>
          User Management
        </Link>

        <div className="">
          <Dropdown
            style={{ position: "absolute", bottom: "0", width: "260px" }}
          >
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="transparent"
              className="transparent w-100 user-profile-sec text-white"
            >
              <div className="clearfix">
                <div className="float-start">
                  <BiSolidUserRectangle className="h2" />{" "}
                </div>
                <div className="float-end">
                  <p className="fw-bold text-black"> {loginUser?.name} </p>
                  <small className="text-black"> {loginUser?.role} </small>
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleLogout()}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </aside>
    </>
  );
}
export default SideBar;
