import { Formik } from "formik";
import { useState } from "react";
import { Button, Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { addFeedbackAsync } from "../../../slices/support-feedback/supportFeedbackSlice";

function FeedbackModal(props) {
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  console.log("rating", rating);
  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating);
  };
  return (
    <Container>
      {" "}
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Your Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              message: "",
              product_id: props.productId,
              rating: rating,
            }}
            validationSchema={Yup.object().shape({
              message: Yup.string().required("Comment  is required"),
            })}
            onSubmit={(values) => {
              const payload = {
                ...values,
                rating: rating,
              };
              dispatch(addFeedbackAsync(payload));

              props.closeFeedbackModal();
            }}
          >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  className="my-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <FloatingLabel controlId="floatingTextarea2" label="Comments">
                    <Form.Control
                      as="textarea"
                      placeholder="Please enter your feedback"
                      style={{ height: "100px" }}
                      autoFocus
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                    />
                    {errors.message && touched.message ? (
                      <small className="text-danger">{errors.message}</small>
                    ) : null}
                  </FloatingLabel>
                </Form.Group>
                <div className="my-4">
                  <small>Rate your experience</small>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <Button
                      key={value}
                      variant="light"
                      className={`border-1 rounded-pill mx-2 star-button ${
                        value <= rating ? "selected" : ""
                      }`}
                      onClick={() => handleRatingClick(value)}
                    >
                      {value}
                    </Button>
                  ))}
                </div>

                <div className="my-3">
                  <Button
                    type="submit"
                    className=" px-3 border-0"
                    variant="primary"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
export default FeedbackModal;
