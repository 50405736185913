import moment from "moment";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateSupportStatusById } from "../../../../slices/support-feedback/supportFeedbackSlice";

function SupportModal(props) {
  const dispatch = useDispatch();

  const suportDetails = useSelector(
    (state) => state.support?.supportByIdResponse?.data
  );

  const handleManage = (id) => {
    dispatch(
      updateSupportStatusById({
        id: id,
        data: {
          is_closed: true,
        },
      })
    );
    props.closeSupportModal(false);
  };

  return (
    <Container>
      {" "}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Support - Contact Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={6}>
                <div className="mb-5">
                  <small> Name</small>
                  <h6> {suportDetails?.customer_name} </h6>
                </div>
                <div className="mb-5">
                  <small> Request Date</small>
                  <h6>{moment(suportDetails?.created_at).format("LL")}</h6>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-5">
                  <small> Phone Number</small>
                  <h6>{suportDetails?.customer_phone}</h6>
                </div>

                <div className="mb-5">
                  <small> Email</small>
                  <h6>{suportDetails?.customer_email}</h6>
                </div>
              </Col>
            </Row>
            <hr />
            <div className="my-5">
              <h6 className="fw-bold">Message</h6>
              <p>{suportDetails?.message}</p>
            </div>
          </Container>
          <div className="my-3">
            <Button
              variant="outline-success"
              className="p-2 me-3"
              onClick={() => handleManage(suportDetails.id)}
            >
              Mark as Replied{" "}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
export default SupportModal;
