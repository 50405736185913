import { Formik } from "formik";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { AiTwotoneMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import * as Yup from "yup";
import { supportFormAsync } from "../../slices/support-feedback/supportFeedbackSlice";
import { useDispatch } from "react-redux";

function Support(props) {
  const dispatch = useDispatch();

  return (
    <div>
      <Offcanvas {...props} className="offcanvas-size-xl">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Support</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="fqa-footer-section p-3">
            <Row>
              <Col md={6}>
                <div>
                  <small>
                    {" "}
                    <BsFillTelephoneFill /> Call Only
                  </small>
                  <h6>+234 904 664 8867</h6>
                </div>
                <div>
                  <small>
                    {" "}
                    <BsFillTelephoneFill /> Whatsapp / Call
                  </small>
                  <h6>+234 912 000 0601</h6>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <small>
                    {" "}
                    <AiTwotoneMail /> Email Support
                  </small>
                  <h6>info@rokswoodenergy.com</h6>
                </div>
              </Col>
            </Row>
          </div>

          <div className="my-5">
            <h4>Contact Form</h4>
            <Formik
              initialValues={{
                customer_email: "",
                customer_name: "",
                customer_phone: "",
                message: "",
              }}
              validationSchema={Yup.object().shape({
                customer_email: Yup.string()
                  .email("Invalid email")
                  .required("Email is required"),

                customer_name: Yup.string().required("Name is required."),
                customer_phone: Yup.string().required("Phone is required."),

                message: Yup.string().required("Message is required."),
              })}
              onSubmit={(values) => {
                dispatch(supportFormAsync(values));
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                onSubmit,
                values,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        name="customer_name"
                        value={values.customer_name}
                        onChange={handleChange}
                      />
                      {errors.customer_name && touched.customer_name ? (
                        <small className="text-danger">
                          {errors.customer_name}
                        </small>
                      ) : null}
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email address"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="customer_email"
                        value={values.customer_email}
                        onChange={handleChange}
                      />
                      {errors.customer_email && touched.customer_email ? (
                        <small className="text-danger">
                          {errors.customer_email}
                        </small>
                      ) : null}
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Phone Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Phone Number"
                        name="customer_phone"
                        value={values.customer_phone}
                        onChange={handleChange}
                      />
                      {errors.customer_phone && touched.customer_phone ? (
                        <small className="text-danger">
                          {errors.customer_phone}
                        </small>
                      ) : null}
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Your Message"
                      className="mb-3"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Your Message"
                        style={{ height: "100px" }}
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                      {errors.message && touched.message ? (
                        <small className="text-danger">{errors.message}</small>
                      ) : null}
                    </FloatingLabel>
                  </Form.Group>
                  <div className="my-4">
                    <Button type="submit" className=" px-5 py-3 border-0">
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
export default Support;
