import { Container } from "react-bootstrap";

function Footer() {
  return (
    <footer>
      <div className="footer-bg-color p-3">
        <Container>
          <p>
            {" "}
            <small className="text-white">
              Copyright © {new Date().getFullYear()} Rokswood Energy
            </small>
          </p>
        </Container>
      </div>
    </footer>
  );
}
export default Footer;
