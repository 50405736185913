import { GetRequest } from "../../util/apiMethods";

export const Roles = async () => {
  const response = await GetRequest("/roles");
  return response;
};

export const Positions = async () => {
  const response = await GetRequest("/positions");
  return response;
};

export const Departments = async () => {
  const response = await GetRequest("/departments");
  return response;
};
