import AdminLayout from "../../../components/layouts/admin-layout";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { IoMdAddCircle } from "react-icons/io";
import AddUser from "./modal/add-user";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDepartment,
  getAllPosition,
  getAllRoles,
} from "../../../slices/data/dataSlice";
import {
  fetchUsersDetailsById,
  fetchUsersDetails,
  viewUserActivityLogAsync,
} from "../../../slices/user/userSlice";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import EditUser from "./modal/edit-user";
import ViewUser from "./modal/view-user";
import DateTimeDisplay from "../../../util/date";
import PaginationComponent from "../../../util/pagination";
import queryString from "query-string";

function Users() {
  const [modalAddUser, setModalAddUser] = useState(false);
  const [modalEditUser, setModalEditUser] = useState(false);
  const [modalViewUser, setModalViewUser] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState([]);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.allUserDetails);
  const handleEdit = (id, detailed) => {
    dispatch(fetchUsersDetailsById({ id, detailed: true }));
    setModalEditUser(true);
    const dataToUpdate = users?.data?.items?.filter((data) => data.id === id);
    setDataToUpdate(dataToUpdate);
  };

  const handleView = async (id, page, detailed) => {
    try {
      await dispatch(fetchUsersDetailsById({ id, detailed: true }));

      await dispatch(viewUserActivityLogAsync({ id, page: "all" }));
      setModalViewUser(true);
    } catch (error) {
      // Handle any errors that might occur during the dispatch
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    try {
      dispatch(getAllRoles());
      dispatch(getAllPosition());
      dispatch(getAllDepartment());
    } catch (error) {
      console.log(error);
    }
  }, []);
  const closeAddModal = () => {
    setModalAddUser(false);
  };
  const closeEditModal = () => {
    setModalEditUser(false);
  };

  const closeViewModal = () => {
    setModalViewUser(false);
  };
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  useEffect(() => {
    const parsedQuery = queryString.parse(window.location.search);
    const pageParam = parseInt(parsedQuery.page) || 1;
    const sizeParam = parseInt(parsedQuery.size) || 10;

    dispatch(
      fetchUsersDetails({ detailed: false, page: pageParam, size: sizeParam })
    );
    setPage(pageParam);
    setSize(sizeParam);
  }, [window.location.search]);

  const handlePageChange = (newPage) => {
    const maxPage = Math.ceil(users.length / size);

    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > maxPage) {
      newPage = maxPage;
    }

    dispatch(fetchUsersDetails({ detailed: false, page: newPage, size }));

    const newQuery = queryString.stringify({
      ...queryString.parse(window.location.search),
      page: newPage,
    });
    window.history.pushState(null, "", `?${newQuery}`);
  };

  const maxPage = Math.ceil(users.length / size);
  return (
    <AdminLayout>
      <AddUser
        show={modalAddUser}
        onHide={() => setModalAddUser(false)}
        closeAddModal={closeAddModal}
      />
      <EditUser
        show={modalEditUser}
        onHide={() => setModalEditUser(false)}
        closeEditModal={closeEditModal}
        data={dataToUpdate}
      />
      <ViewUser
        show={modalViewUser}
        onHide={() => setModalViewUser(false)}
        closeViewModal={closeViewModal}
      />
      <Container>
        <div className="mt-5">
          <h5 className="fw-bold">User Management</h5>
          <DateTimeDisplay />
        </div>
        <div className="my-5">
          <Row>
            <Col md={5} className="p-0 m-0"></Col>
            <Col md={7}>
              <div>
                <Button
                  variant="light"
                  className="p-2 ms-2 float-end"
                  onClick={() => setModalAddUser(true)}
                >
                  <IoMdAddCircle className="text-success " /> Add User
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Card>
          <Card.Body>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Staff ID</th>
                  <th> Name</th>
                  <th>Department</th>
                  <th> Position</th>
                  <th> Email</th>
                  <th> Role</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users?.data?.items.map((user, index) => (
                  <tr key={index}>
                    <td> {index + 1} </td>
                    <td> {user?.id_card_number} </td>
                    <td className="text-capitalize">
                      <div>
                        <Image
                          src={user.image}
                          className="img-fluid img-circle me-2"
                        />{" "}
                        <span> {user?.firstname}</span>
                      </div>
                    </td>
                    <td className="text-capitalize"> {user?.department} </td>
                    <td className="text-capitalize"> {user?.position} </td>
                    <td> {user.email} </td>
                    <td className="text-capitalize"> {user.role} </td>
                    <td>
                      {user.status === "active" ? (
                        <span className="px-4 py-1  rounded  border border-success">
                          Active{" "}
                        </span>
                      ) : (
                        <span className="px-2 py-1  rounded border border-danger ">
                          Not Active{" "}
                        </span>
                      )}
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="light" className=" border-0">
                          <BiDotsHorizontalRounded className="fw-bold " />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleEdit(user.id)}
                            className="small"
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleView(user.id)}
                            className="small"
                          >
                            View
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <div>
          <PaginationComponent
            currentPage={page}
            totalPages={maxPage}
            onPageChange={handlePageChange}
          />
        </div>
      </Container>
    </AdminLayout>
  );
}
export default Users;
