import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  createUser,
  updateUser,
  deleteUser,
  getAllUsers,
  getAllUsersDetails,
  getUserById,
  viewUserActivityLog,
  activateUser,
  deactivateUser,
} from "../../services/user/userService";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async ({ detailed, page, size }) => {
    const response = await getAllUsers(detailed, page, size);
    return response;
  }
);

export const fetchUsersDetails = createAsyncThunk(
  "users/fetchUsers/details",
  async ({ detailed, page, size }) => {
    const response = await getAllUsersDetails(detailed, page, size);
    return response;
  }
);

export const fetchUsersDetailsById = createAsyncThunk(
  "users/fetchUsers/details/by/id",
  async ({ detailed, id }) => {
    const response = await getUserById(id, detailed);
    return response;
  }
);

export const addUser = createAsyncThunk("users/createUser", async (values) => {
  const createdUser = await createUser(values);
  return createdUser;
});

export const updateUserAsync = createAsyncThunk(
  "users/update",
  async ({ id, data }) => {
    const response = await updateUser(id, data);
    return response;
  }
);

export const viewUserActivityLogAsync = createAsyncThunk(
  "users/view/activity/log",
  async ({ id, page }) => {
    const response = await viewUserActivityLog(id, page);
    return response;
  }
);

export const deleteUserAsync = createAsyncThunk("users/delete", async (id) => {
  const response = await deleteUser(id);
  return response;
});
export const activateUserAsync = createAsyncThunk(
  "users/activate",
  async (id) => {
    const response = await activateUser(id);
    return response;
  }
);
export const deactivateUserAsync = createAsyncThunk(
  "users/deactivate",
  async (id) => {
    const response = await deactivateUser(id);
    return response;
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: {
    addUserResponse: {},
    allUsers: {},
    allUserDetails: {},
    userDetailsByIdResponse: {},
    updateUserResponse: {},
    deleteUserResponse: {},
    activateUserResponse: {},
    deactivateUserResponse: {},
    viewUserActivityLogResponse: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
    });

    builder.addCase(fetchUsersDetails.fulfilled, (state, action) => {
      state.allUserDetails = action.payload;
    });

    builder.addCase(addUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.addUserResponse = action.payload;

        state.allUserDetails.data.items.unshift({
          id: action.payload?.data?.id,
          email: action.payload?.data?.email,
          department: action.payload?.data?.department,
          is_active: action.payload?.data?.is_active,
          position: action.payload?.data?.position,
          role: action.payload?.data?.role,
          name: action.payload?.data?.name,
          image: action.payload?.data?.image,
          id_card_number: action.payload.data.id_card_number,
        });
        toast.success(action.payload.message);
      }
    });

    builder.addCase(addUser.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(fetchUsersDetailsById.fulfilled, (state, action) => {
      state.userDetailsByIdResponse = action.payload;
    });
    builder.addCase(updateUserAsync.fulfilled, (state, action) => {
      state.userDetailsByIdResponse = action.payload;
      toast.success(action.payload.message);
    });
    builder.addCase(viewUserActivityLogAsync.fulfilled, (state, action) => {
      state.viewUserActivityLogResponse = action.payload;
    });

    builder.addCase(deleteUserAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.deleteUserResponse = action.payload;

        state.allUserDetails.data.items.pop({
          id: action.payload?.data?.id,
          email: action.payload?.data?.email,
          department: action.payload?.data?.department,
          is_active: action.payload?.data?.is_active,
          position: action.payload?.data?.position,
          role: action.payload?.data?.role,
          name: action.payload?.data?.name,
          id_card_number: action.payload.data.id_card_number,
        });
        toast.success(action.payload.message);
      }
    });

    builder.addCase(activateUserAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.activateUserResponse = action.payload;

        state.allUserDetails.data.items.unshift({
          id: action.payload?.data?.id,
          email: action.payload?.data?.email,
          department: action.payload?.data?.department,
          is_active: action.payload?.data?.is_active,
          position: action.payload?.data?.position,
          role: action.payload?.data?.role,
          name: action.payload?.data?.name,
          image: action.payload?.data?.image,
          id_card_number: action.payload.data.id_card_number,
        });
        toast.success(action.payload.message);
      }
    });
    builder.addCase(activateUserAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(deactivateUserAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.deactivateUserResponse = action.payload;

        state.allUserDetails.data.items.unshift({
          id: action.payload?.data?.id,
          email: action.payload?.data?.email,
          department: action.payload?.data?.department,
          is_active: action.payload?.data?.is_active,
          position: action.payload?.data?.position,
          role: action.payload?.data?.role,
          name: action.payload?.data?.name,
          image: action.payload?.data?.image,
          id_card_number: action.payload.data.id_card_number,
        });
        toast.success(action.payload.message);
      }
    });
    builder.addCase(deactivateUserAsync.rejected, (state, action) => {
      toast.error("Error: Failed please try again");
    });
  },
});

export default userSlice.reducer;
