import { GetRequest, PostRequest, PutRequest } from "../../util/apiMethods";

export const AllProducts = async (detailed, page, size) => {
  const response = await GetRequest(
    `/products?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const AddProduct = async (body) => {
  const response = await PostRequest("/products", body);
  return response;
};

export const GetProductById = async (id, detailed) => {
  const response = await GetRequest(`/products/${id}?detailed=${detailed}`);
  return response;
};
export const UpdateProductById = async (id, body) => {
  const response = await PutRequest(`/products/${id}`, body);
  return response;
};

export const GetProductStats = async () => {
  const response = await GetRequest("/products/stats");
  return response;
};
export const GetProductFeedback = async (productId) => {
  const response = await GetRequest(`products/${productId}/feedbacks`);
  return response;
};

export const ValidateProduct = async (product_sn, detailed) => {
  const response = await GetRequest(
    `verifications/${product_sn}?detailed=${detailed}`
  );
  return response;
};

export const GetProductSerialNumber = async () => {
  const response = await GetRequest("/products/new_serial_number");
  return response;
};
