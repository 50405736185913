import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  activateUserAsync,
  deactivateUserAsync,
} from "../../../../slices/user/userSlice";

function ViewUser(props) {
  const dispatch = useDispatch();
  const userActivityLog = useSelector(
    (state) => state.users?.viewUserActivityLogResponse?.data?.items
  );
  const userInfo = useSelector(
    (state) => state.users?.userDetailsByIdResponse?.data
  );
  const handleDeactivate = (id) => {
    dispatch(deactivateUserAsync(id));
    props.closeViewModal();
  };
  const handleActivate = (id) => {
    dispatch(activateUserAsync(id));
    props.closeViewModal();
  };
  return (
    <>
      <Container>
        {" "}
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold text-uppercase">
              ID: {userInfo?.id_card_number}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="clearfix">
              <div className="my-1 float-end">
                {userInfo?.is_active ? (
                  <Button
                    variant="outline-danger"
                    onClick={() => handleDeactivate(userInfo.id)}
                  >
                    Deactivate
                  </Button>
                ) : (
                  <Button
                    variant="outline-success"
                    onClick={() => handleActivate(userInfo.id)}
                  >
                    Activate
                  </Button>
                )}
              </div>
            </div>
            <Row>
              <Col md={3}>
                <Card>
                  <Card.Body>
                    <div className=" text-center">
                      <div className="upload-picture-sec">
                        <Image
                          src={userInfo?.image}
                          width={100}
                          className="upload-picture-sec"
                          alt="Image from API"
                        />
                      </div>

                      <h6> {userInfo?.name} </h6>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={9}>
                <Row className="mb-4">
                  <Col md={4}>
                    <div>
                      <small>ID Card Number</small>
                      <h6>{userInfo?.id_card_number}</h6>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <small>Date Of Appointment</small>
                      <h6>{moment(userInfo?.appointment_date).format("LL")}</h6>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <small>Email</small>
                      <h6>{userInfo?.email}</h6>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md={4}>
                    <div>
                      <small>Role</small>
                      <h6>{userInfo?.role}</h6>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <small>Department</small>
                      <h6> {userInfo?.department} </h6>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <small>Position</small>
                      <h6>{userInfo?.position}</h6>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md={4}>
                    <div>
                      <small>Status</small>
                      <h6 className="mt-1">
                        {userInfo?.is_active === true ? (
                          <span className="px-4 py-1  rounded  border border-success">
                            Active{" "}
                          </span>
                        ) : (
                          <span className="px-2 py-1  rounded border border-danger ">
                            Not Active{" "}
                          </span>
                        )}
                      </h6>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <small>Last Session</small>
                      <h6>{moment(userInfo?.last_session).format("LLL")}</h6>
                    </div>
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </Col>
            </Row>
            <div className="modal-header-bg p-1">
              <h6 className="text-uppercase">Activity log</h6>
            </div>
            <Table hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>IP/Computer</th>
                  <th>Description </th>
                  <th>Reference Number</th>
                  <th>Create</th>
                </tr>
              </thead>
              <tbody>
                {userActivityLog?.map((log, index) => (
                  <tr key={index}>
                    <td> {index + 1} </td>
                    <td> {log?.remote_address} </td>
                    <td> {log?.description} </td>
                    <td> {log?.reference} </td>
                    <td> {moment(log?.created_at).format("LL")} </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}
export default ViewUser;
