import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import originalIcon from "../../assests/images/original-img.png";
import originalIconBig from "../../assests/images/original-img-big.png";

import { RiFeedbackFill } from "react-icons/ri";
import { AiOutlinePrinter } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
import FeedbackModal from "./modals/feedback-modal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

function ProductDetails() {
  const [modalFeedback, setModalFeedback] = useState(false);

  const productDetails = useSelector(
    (state) => state.products.validateProductResponse
  );
  const productId = productDetails?.data?.id;
  const closeFeedbackModal = () => {
    setModalFeedback(false);
  };
  const handleFeedback = () => {
    setModalFeedback(true);
  };
  return (
    <Container fluid>
      <FeedbackModal
        show={modalFeedback}
        onHide={() => setModalFeedback(false)}
        productId={productId}
        closeFeedbackModal={closeFeedbackModal}
      />{" "}
      <Row className=" p-2 clearfix  ">
        <Col md={4}>
          {" "}
          <h4 className="mt-1">{productDetails?.data?.serial_number}</h4>{" "}
        </Col>
        <Col md={4}>
          {" "}
          <h6 className="">
            {" "}
            Your Cylinder is <span className="text-danger">Genuine</span>
            <Image src={originalIcon} className="img-fluid ms-3" width={50} />
          </h6>
        </Col>
        <Col md={4}>
          <Link to="/" className="float-end">
            <MdCancel className="text-dark h4" />
          </Link>
        </Col>
      </Row>
      <div className="mb-5">
        <Row>
          <Col md={4}>
            <div>
              <Image src={productDetails?.data?.image} className="img-fluid" />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-5">
              <small>Cylinder Serial Number</small>
              <h6>{productDetails?.data?.serial_number}</h6>
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-5">
                  <small>Manufacture Date</small>
                  <h6>
                    {moment(productDetails?.data?.manufacture_date).format(
                      "LL"
                    )}
                  </h6>
                </div>
                <div className="mb-5">
                  <small className="text-danger">Expire Date</small>
                  <h6>
                    {moment(productDetails?.data?.expiry_date).format("LL")}
                  </h6>
                </div>
              </Col>
              <Col md={6}></Col>
              <div className="mb-5">
                <small>Pressure Test Certificate</small>
                <h6>
                  <a
                    href={productDetails?.data?.pressure_test_certificate}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Pressure Test Certificate
                  </a>
                </h6>
              </div>
            </Row>
          </Col>
          <Col md={4}>
            {" "}
            <div>
              <Image src={productDetails?.data?.qrcode} className="img-fluid" />
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col md={12} className="">
            <Card className="rounded-0 bg-warning-subtle border-0">
              <Card.Body>
                <h5>Technical Parameters</h5>
                <Row>
                  <Col md={3}>
                    <div className="mb-5">
                      <small>Thickness</small>
                      <h6>{productDetails?.data?.thickness}</h6>
                    </div>
                    <div className="mb-5">
                      <small>Color </small>
                      <h6>{productDetails?.data?.color}</h6>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-5">
                      <small>Volume </small>
                      <h6>{productDetails?.data?.volume}</h6>
                    </div>
                    <div className="mb-5">
                      <small>Allowable Pressure </small>
                      <h6>{productDetails?.data?.allowable_pressure}</h6>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-5">
                      <small>Weight </small>
                      <h6>{productDetails?.data?.weight}</h6>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mt-5 text-center">
                      <Image src={originalIconBig} width={120} />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="my-5">
        <Row>
          <Col md={8}>
            {" "}
            <div className="mt-5">
              <Button
                variant="primary rounded-0"
                onClick={() => handleFeedback()}
              >
                {" "}
                <RiFeedbackFill /> Give Feedback
              </Button>{" "}
            </div>{" "}
          </Col>
          <Col md={4}>
            <div className="mt-5 pt-5 float-end">
              <Button variant="light" className="light border-secondary">
                <AiOutlinePrinter />
                Print
              </Button>{" "}
              <Button variant="light" className="light border-secondary">
                {" "}
                <BsDownload /> Download
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default ProductDetails;
