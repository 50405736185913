import { Formik } from "formik";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { MdArrowForwardIos } from "react-icons/md";
import logo from "../../../assests/images/Wooden-heritage-logo-white.png";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPasswordAsync } from "../../../slices/auth/authSlice";
import * as Yup from "yup";

function ResetPassword({ match }) {
  const dispatch = useDispatch();
  const token = useParams();

  const passtoken = token.token;

  return (
    <div className="">
      <Row>
        <Col md={7} className="">
          <div className="login-img-side d-sm-none d-none d-md-block  "></div>
        </Col>
        <Col md={5}>
          <Container className="d-flex align-items-center justify-content-center pt-5">
            <Card className="border-0  pt-5  text-center rounded mx-5 login-card ">
              <Card.Body>
                <div className="p-4">
                  <h3 className="">Welcome Admin</h3>
                  <p className="">Enter New Password</p>
                </div>
                <div className="mt-3 px-3">
                  <Formik
                    initialValues={{
                      password: "",
                    }}
                    validationSchema={Yup.object().shape({
                      password: Yup.string()
                        .required("No password provided.")
                        .min(
                          6,
                          "Password is too short - should be 6 chars minimum."
                        ),

                      confirm_password: Yup.string().oneOf(
                        [Yup.ref("password"), null],
                        "Passwords do not match"
                      ),
                    })}
                    onSubmit={(values) => {
                      dispatch(
                        resetPasswordAsync({
                          credentials: values,
                          token: passtoken,
                        })
                      );
                    }}
                    validateOnChange
                    validateOnBlur
                    validateOnSubmit
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                      values,
                      handleChange,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Group>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Password"
                            className="my-3"
                          >
                            <Form.Control
                              placeholder="Enter your password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              className="p-3 password-input"
                              type="password"
                            />
                            {errors.password && touched.password ? (
                              <small className="text-danger">
                                {errors.password}
                              </small>
                            ) : null}
                          </FloatingLabel>
                        </Form.Group>
                        <Form.Group>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Confirm Password"
                            className="my-3"
                          >
                            <Form.Control
                              placeholder="Enter your password"
                              name="confirm_password"
                              value={values.confirm_password}
                              onChange={handleChange}
                              className="p-3 password-input"
                              type="password"
                            />
                            {errors.confirm_password &&
                            touched.confirm_password ? (
                              <small className="text-danger">
                                {errors.confirm_password}
                              </small>
                            ) : null}
                          </FloatingLabel>
                        </Form.Group>
                        <div className="d-grid gap-2">
                          <Button
                            type="submit"
                            variant="primary"
                            className=" my-4 py-3  border-0 clearfix text-white"
                          >
                            <small className="float-start ">Submit</small>
                            <MdArrowForwardIos className="float-end" />
                          </Button>
                        </div>
                        <div className="mb-3">
                          <Link
                            to="/login"
                            className=" border border-1 px-5 py-3 rounded"
                          >
                            Login
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
    </div>
  );
}
export default ResetPassword;
