import { Formik } from "formik";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import logo from "../../../assests/images/R-Energy-White.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync } from "../../../slices/auth/authSlice";
import * as Yup from "yup";

function Login() {
  const userLogin = useSelector((state) => state.auth.loginResponse);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (userLogin?.status === true) {
    window.location.href = "/home";
  }

  return (
    <>
      <Row>
        <Col md={7} className="">
          <div className="login-img-side d-sm-none d-none d-md-block  "></div>
        </Col>
        <Col md={5}>
          <div className="">
            <Container className="d-flex align-items-center justify-content-center pt-5">
              <Card className="border-0  pt-5  text-center rounded mx-5 login-card ">
                <Card.Body>
                  <div className="p-4">
                    <h3 className="text-primary">Sign In</h3>
                  </div>
                  <div className="mt-3 px-3">
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email("Invalid email")
                          .required("Email is required"),
                        password: Yup.string()
                          .required("No password provided.")
                          .min(
                            6,
                            "Password is too short - should be 6 chars minimum."
                          ),
                      })}
                      onSubmit={(values) => {
                        dispatch(loginAsync(values));
                      }}
                      validateOnChange
                      validateOnBlur
                      validateOnSubmit
                    >
                      {({
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                        values,
                        handleChange,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Username"
                              className="my-3"
                            >
                              <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                              />
                              {errors.email && touched.email ? (
                                <small className="text-danger">
                                  {errors.email}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Password"
                              className="my-3"
                            >
                              <Form.Control
                                placeholder="Enter your password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                className="p-3 password-input"
                                type="password"
                              />
                              {errors.password && touched.password ? (
                                <small className="text-danger">
                                  {errors.password}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                          <div className="d-grid gap-2">
                            <Button
                              type="submit"
                              className=" my-4 py-3  border-0  "
                            >
                              <span className="text-center">Sign in</span>
                            </Button>
                          </div>
                          <div className="mb-3">
                            <Link
                              to="/forget-password"
                              className=" border border-1 p-2 rounded"
                            >
                              Forgot your Password?
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Login;
